import {
  observable,
  action
} from 'mobx';

import APIUtils from '../utils/api-utils';

import {
  API
} from '../config';

class BusinessStore {
  @observable
  businessDetails = null;

  constructor() {}

  @action.bound
  async resetBusinessDetails() {
    this.businessDetails = null;
  }

  @action.bound
  async printQRPage(businesId, token) {
    try {
      const req = await APIUtils.apiCall(
        API.BUSINESS_PRINT_QR
        .replace('{businessId}', businesId),
        null,
        token,
        'get'
      );

      if (req.status === 200) {
        const reqBlob = await req.blob();

        return reqBlob;
      }
      return false;
    } catch (err) {
      console.error('problem printing page: ', businesId);
      return false;
    }
  }

  @action.bound
  async registerUser(businessId, token) {
    try {
      const req = await APIUtils.apiCall(
        API.BUSINESS_REGISTER_USER
        .replace('{businessId}', businessId),
        null,
        token,
        'post'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          return true;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error('problem updating registering user: ', businessId);
      return false;
    }
  }

  @action.bound
  async updateTicketStatus(storeCode, ticketId, status, token) {
    try {
      const req = await APIUtils.apiCall(
        API.ADMIN_UPDATE_Q_STATUS
          .replace('{code}', storeCode)
          .replace('{ticketId}', ticketId)
          .replace('{status}', status),
        null,
        token,
        'post'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          return true;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error('problem updating ticket status: ', ticketId, storeCode);
      return false;
    }
  }

  @action.bound
  async checkInTicket(storeCode, ticketId, token) {
    try {
      const req = await APIUtils.apiCall(
        API.ADMIN_CHECKIN_Q.replace('{code}', storeCode).replace('{ticketId}', ticketId),
        null,
        token,
        'post'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          return true;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error('problem checking in q: ', ticketId, storeCode);
      return false;
    }
  }

  @action.bound
  async callTicket(storeCode, ticketId, token) {
    try {
      const req = await APIUtils.apiCall(
        API.ADMIN_CALL_Q.replace('{code}', storeCode).replace('{ticketId}', ticketId),
        null,
        token,
        'post'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          return true;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error('problem calling q: ', ticketId, storeCode);
      return false;
    }
  }

  @action.bound
  async joinQ(storeCode, token) {
    try {
      const req = await APIUtils.apiCall(
        API.ADMIN_Q_JOIN.replace('{code}', storeCode),
        null,
        token,
        'post'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          return true;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error('problem joining q: ', storeCode);
      return false;
    }
  }

  @action.bound
  async updateInStoreCounter(businessId, numberToReduceOrAdd, token) {
    try {
      const req = await APIUtils.apiCall(
        API.BUSINESS_UPDATE_INSTORE.replace('{businessId}', businessId),
        {
          update_counter_by: numberToReduceOrAdd
        },
        token,
        'post'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          return true;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error(err);
      console.error('problem updating in-store counter: ', businessId);
      return false;
    }
  }

  @action.bound
  async updateExpirationTime(businessId, expirationTime, token) {
    try {
      if (expirationTime < 1 || expirationTime > 10) {
        return false;
      }

      const req = await APIUtils.apiCall(
        API.BUSINESS_UPDATE.replace('{businessId}', businessId), {
          expiration_time: expirationTime
        },
        token,
        'patch'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          return true;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error('problem updating business expiration time: ', businessId);
      return false;
    }
  }

  @action.bound
  async reqister(name, location , token) {
    try {
      const req = await APIUtils.apiCall(
        API.BUSINESS_REGISTER,
        {
          name,
          expiration_time: 2,
          location
        },
        token,
        'post'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          this.businessDetails = {
            ...reqBody.data
          };

          return this.businessDetails;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error('problem registering business: ', name);
      return false;
    }
  }
}

export default BusinessStore;
