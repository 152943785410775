const FIREBASE_APP_CONFIG = {
  apiKey: 'AIzaSyCv22dh94oq531_3LtzJbemGMTsNbUETW0',
  authDomain: 'project-qup.firebaseapp.com',
  databaseURL: 'https://project-qup.firebaseio.com',
  projectId: 'project-qup',
  storageBucket: 'project-qup.appspot.com',
  messagingSenderId: '353807453665',
  appId: '1:353807453665:web:e6fbc9b6a23ddfc82056a0',
  measurementId: 'G-SN1J5VFSRR'
};

const BASE_ROUTE = '';

const BUCKET_FILE_BASE_URL = () => process.env.NODE_ENV === 'production' ? 'https://storage.googleapis.com/project-qup/static/' : '/';

const FIREBASE_SETTINGS = {
  webPushCertificate: 'BL3zQoe5ixW2jbLCdcPLOVILwH7kDDxdZ5oiV756xDoYgCRaKVyMZByUPyTnv1uw5wLqf74BImFV6uZqQ0ZYt3s'
};

const API_BASE = 'https://us-central1-project-qup.cloudfunctions.net/api';

const API = {
  CUSTOMER_Q_JOIN: `${API_BASE}/tickets/customer/{code}/join`,
  CUSTOMER_Q_LEAVE: `${API_BASE}/tickets/customer/{code}/{ticketId}/leave`,
  CUSTOMER_Q_NOTIFICATION: `${API_BASE}/tickets/customer/{code}/{ticketId}/notification`,
  BUSINESS_REGISTER: `${API_BASE}/business`,
  BUSINESS_PRINT_QR: `${API_BASE}/business/{businessId}/print`,
  BUSINESS_UPDATE: `${API_BASE}/business/{businessId}`,
  BUSINESS_REGISTER_USER: `${API_BASE}/business/{businessId}/register`,
  BUSINESS_UPDATE_INSTORE: `${API_BASE}/business/{businessId}/in-store/update`,
  BUSINESS_RESET_TICKETS: `${API_BASE}/business/{businessId}/tickets/reset`,
  ADMIN_Q_JOIN: `${API_BASE}/tickets/admin/{code}/join`,
  ADMIN_CALL_Q: `${API_BASE}/tickets/admin/{code}/{ticketId}/call`,
  ADMIN_CHECKIN_Q: `${API_BASE}/tickets/admin/{code}/{ticketId}/check-in`,
  ADMIN_UPDATE_Q_STATUS: `${API_BASE}/tickets/admin/{code}/{ticketId}/status/{status}`
};

const DB_REF = {
  BUSINESS: 'business-details',
  STORE_CODES: 'store-codes',
  TICKETS: 'tickets'
};

const TICKET_STATUS = {
  OPEN: 'OPEN',
  CALLED: 'CALLED',
  CHECKED_IN: 'CHECKED_IN',
  EXPIRED: 'EXPIRED',
  REMOVED: 'REMOVED',
  CLOSED: 'CLOSED'
};

const TICKET_STATUS_DOC_TITLE = {
  CALLED: '📣 We\'re ready for you! | Line Up with Google',
  CHECKED_IN: '✔️ Successfully checked-in | Line Up with Google',
  OPEN: '⏱️ Waiting for your number to be called | Line Up with Google',
  EXPIRED: '😔 Time expired | Line Up with Google',
  DEFAULT: 'Line Up with Google.',
};

const TICKET_PAD_LEN = 3;

const COLOURS = {
  BLUE_25: '#f4f8fe',
  BLUE_50: '#e8f0fe',
  BLUE_100: '#d2e3fc',
  BLUE_500: '#4285f4',
  BLUE_600: '#1a73e8',
  BLUE_800: '#185abc',
  BLUE_900: '#174ea6',
  CYAN_600: '#12b5cb',
  CYAN_800: '#098591',
  CYAN_900: '#007b83',
  GRAY_50: '#f8f9fa',
  GRAY_100: '#f1f3f4',
  GRAY_200: '#e8eaed',
  GRAY_300: '#dadce0',
  GRAY_400: '#bdc1c6',
  GRAY_500: '#9aa0a6',
  GRAY_600: '#80868b',
  GRAY_700: '#5f6368',
  GRAY_800: '#3c4043',
  GRAY_900: '#202124',
  GREEN: '#1de9b6',
  GREEN_100: '#ceead6',
  GREEN_200: '#b4f2e2',
  GREEN_300: '#1eeab7',
  GREEN_400: '#5bb974',
  GREEN_500: '#34a853',
  GREEN_600: '#1e8e3e',
  GREEN_700: '#188038',
  RED_100: '#fad2cf',
  RED_500: '#f44336',
  RED_600: '#d93025',
  RED_800: '#b31412',
  RED_900: '#a50e0e',
  YELLOW_300: '#fdd663',
  YELLOW_400: '#fcc934',
  YELLOW_500: '#fbbc04',
  YELLOW_600: '#f9ab00',
  DEEP_PURPLE_50: '#ede7f6',
  DEEP_PURPLE_A100: '#b388ff',
  DEEP_PURPLE_A200: '#7c4dff',
  DEEP_PURPLE_A400: '#651fff',
  DEEP_PURPLE_A700: '#6200ea',
};

const FONT_FAMILIES = {
  GOOGLE_SANS: 'Google Sans, Roboto, sans-serif',
  ROBOTO_MONO: 'Roboto Mono, monospace'
};

const SOUNDS_BUSINESS = {
  OPEN: 'state_change',
  CALLED: 'state_change',
  CHECKED_IN: 'hero_decorative',
  EXPIRED: 'alert_error',
  REMOVED: 'alert_error',
  CLOSED: 'alert_error',
  CLICK: 'navigation_forward',
  CLICK_ERROR: 'ui_tap',
  STATE_CHANGE: 'state_change',
  ADD: 'navigation_forward',
  SUBTRACT: 'ui_tap',
}

const SOUNDS_CUSTOMER = {
  OPEN: 'state_change',
  CALLED: 'state_change',
  CHECKED_IN: 'hero_decorative',
  EXPIRED: 'alert_error',
  REMOVED: 'alert_error',
  CLOSED: 'alert_error',
  CLICK: 'navigation_forward',
  CLICK_ERROR: 'ui_tap',
}

// Local storage item name; flag whether to show install banner.
const LS_PWA_INSTALL_IGNORE = 'qup-pwa-install-ignore';

export {
  API,
  BASE_ROUTE,
  BUCKET_FILE_BASE_URL,
  COLOURS,
  DB_REF,
  FIREBASE_APP_CONFIG,
  FIREBASE_SETTINGS,
  FONT_FAMILIES,
  LS_PWA_INSTALL_IGNORE,
  SOUNDS_CUSTOMER,
  SOUNDS_BUSINESS,
  TICKET_PAD_LEN,
  TICKET_STATUS,
  TICKET_STATUS_DOC_TITLE,
};
