import {
  observable,
  action
} from 'mobx';

class GlobalStore {
  @observable
  showLoader = false;

  @observable
  userSession = null;

  @observable
  adminState = null;

  @observable
  showFooter = true;

  @observable
  installPromptEvent = null;

  constructor() {}

  @action.bound
  setInstallPrompt(e) {
    this.installPromptEvent = e;
  }

  @action.bound
  setLoader(loader) {
    this.showLoader = loader;
  }

  @action.bound
  setFooter(show) {
    this.showFooter = show;
  }

  @action.bound
  setUserSession(session) {
    this.userSession = session ? {
      ...session
    } : null;
  }

  @action.bound
  setAdminState(path, id) {
    // we rely on this state on whether or not to show
    // header component. and set the correct business Id
    this.adminState = {
      id,
      path
    };
  }

  @action.bound
  removeAdminState() {
    this.adminState = null;
  }
}

export default GlobalStore;
