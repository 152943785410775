/**
 * this class is used to create a standard http request with standardised header
 * and in case we are changing the library in the future, just need to change here
 */
export default class APIUtils {
  static _baseOpts = {
    method: 'post',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json'
    }
  };

  /**
   * this method is used to set authorization header value
   * @param {String} token - token value
   */
  static _setToken(token) {
    APIUtils._baseOpts.headers = {
      ...APIUtils._baseOpts.headers,
      Authorization: `Bearer ${token}`
    };
  }

  /**
   * this method is used to construct http request and send it
   * @param {String} url    - url
   * @param {Object} params - key value parameters
   * @param {String} token  - authorization header token value
   * @param {String} method - 'post' or 'get' or 'patch' or 'put' or 'patch'
   *
   * @return {Promise} - fetch object
   */
  static apiCall(url, params, token, method = 'post') {
    if (token) {
      APIUtils._setToken(token);
    }

    let finalURL = url;

    let parameters = {
      ...this._baseOpts,
      method: method || this._baseOpts.method,
    };

    if (params) {
      if (method !== 'get') {
        parameters.body = JSON.stringify(params);
      } else {
        const qs = Object.keys((params)).map((key) => {
          return `${key}=${encodeURIComponent(params[key])}`;
        });

        finalURL += `${finalURL.indexOf('?') === -1 ? '?' : '&'}${qs.join('&')}`;
      }
    }

    return fetch(finalURL, parameters);
  }
}