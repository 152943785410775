import {
  FIREBASE_APP_CONFIG,
  FIREBASE_SETTINGS
} from '../config';

export default class Firebase {
  _firebase = null;

  _firebaseLib = [];
  _messaging = null;


  _firebaseLibReadyCb = null;

  _raf = null;

  constructor(firebaseLibReadyCb) {
    // this._firebaseLib = [
    //   import('firebase/app'),
    //   import('firebase/auth'),
    //   import('firebase/database'),
    //   import('firebase/messaging')
    // ];
    this._firebaseLibReadyCb = firebaseLibReadyCb;
  }

  loadFirebaseLib = async () => {

    if (window.firebase === undefined ||
        window.firebase.auth === undefined ||
        window.firebase.database === undefined ||
        window.firebase.messaging === undefined
    ) {
      this._raf = window.requestAnimationFrame(this.loadFirebaseLib);
      return;
    }

    if (this._raf) {
      window.cancelAnimationFrame(this._raf);
      this._raf = null;
    }

    this._initFirebase();
  }

  async _initFirebase() {
    if (!this._firebase) {
      // const [firebase] = await Promise.all(this._firebaseLib);

      // this._firebase = firebase.default.apps.length === 0 ? firebase.default.initializeApp(FIREBASE_APP_CONFIG) : firebase.default;
      this._firebase = window.firebase.default.apps.length === 0 ? window.firebase.default.initializeApp(FIREBASE_APP_CONFIG) : window.firebase.default;
    }

    if (!this._firebase.messaging) {
      // if messaging still not loaded, let's wait again
      this._firebase = null;
      this._raf = window.requestAnimationFrame(this.loadFirebaseLib);
      return;
    }

    this._firebaseLibReadyCb();
  }

  getFirebaseInstance() {
    return this._firebase;
  }

  getIdToken() {
    return this._firebase.auth().currentUser ? this._firebase.auth().currentUser.getIdToken() : Promise.resolve(null)
  }

  getCurrentUserDetails() {
    return this._firebase.auth().currentUser;
  }

  getFirebaseTimestamp() {
    return this._firebase.database.ServerValue.TIMESTAMP;
  }

  initFCMMessaging() {
    if (this._firebase.messaging.isSupported() && !this._messaging) {
      console.log('init messaging');
      this._messaging = this._firebase.messaging();

      if (!this._messaging.vapidKey) {
        this._messaging.usePublicVapidKey(FIREBASE_SETTINGS.webPushCertificate);

        // Callback fired if Instance ID token is updated
        this._messaging.onTokenRefresh(() => {
          this.getMessagingToken();
        });
      }
    }
  }

  async getMessagingToken() {
    // Check if this on iOS or other devices not supported
    if (!this._firebase.messaging.isSupported()) {
      return;
    }
    try {
      const currentToken = await this._messaging.getToken();
      if (currentToken) {
        console.log(currentToken);
        return currentToken;
      } else {
        // This does not get called
      }
    } catch (err) {
      // Permission blocked by user
      console.error('getMessagingToken: An error occurred while retrieving token. ', err);
    }
  }
}
