import {
  observable,
  action
} from 'mobx';

import APIUtils from '../utils/api-utils';

import {
  API
} from '../config';

class CustomerQueueStore {
  @observable
  ticket = null;

  @observable
  storeCode = null;

  @observable
  isHalfTime = false;

  constructor() {}

  @action.bound
  setHalfTime(halftime) {
    this.isHalfTime = halftime;
  }

  @action.bound
  resetStoreCode() {
    this.storeCode = null;
  }

  @action.bound
  resetTicket() {
    this.ticket = null;
  }

  @action.bound
  async leaveQ(storeCode, ticketId, token) {
    // APIUtils
    try {
      const req = await APIUtils.apiCall(
        API.CUSTOMER_Q_LEAVE
          .replace('{code}', storeCode)
          .replace('{ticketId}', ticketId),
        null,
        token,
        'post'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          this.ticket = {
            ...reqBody.data
          };

          return this.ticket;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error('problem in joining queue for store code: ', storeCode);
      return false;
    }
  }

  @action.bound
  async saveNotification(storeCode, ticketId, notificationToken, token) {
    // APIUtils
    try {
      const req = await APIUtils.apiCall(
        API.CUSTOMER_Q_NOTIFICATION.replace('{code}', storeCode)
          .replace('{ticketId}', ticketId),
        {
          notification_token: notificationToken || null
        },
        token,
        'post'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          this.ticket = {
            ...reqBody.data
          };

          return this.ticket;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error('problem in saving notificatiion token for store code: ', storeCode);
      return false;
    }
  }

  @action.bound
  async joinQueue(storeCode, notificationToken, token) {
    // APIUtils
    try {
      const req = await APIUtils.apiCall(
        API.CUSTOMER_Q_JOIN.replace('{code}', storeCode),
        notificationToken ? { notification_token: notificationToken } : null,
        token,
        'post'
      );

      if (req.status === 200) {
        const reqBody = await req.json();

        if (reqBody.status) {
          this.ticket = {
            ...reqBody.data
          };

          return this.ticket;
        }

        return false;
      }
      return false;
    } catch (err) {
      console.error('problem in joining queue for store code: ', storeCode);
      return false;
    }
  }
}

export default CustomerQueueStore;