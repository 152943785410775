// Must be the first import
import 'preact/debug';
import 'preact/devtools';

import './style';
import { h } from 'preact';

import App from './components/app';
import store from './stores';

import { Provider } from 'mobx-react';

// Global reset for CSS and styles
// https://material-ui.com/components/css-baseline/
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';

// Theme
import theme from './utils/theme';


const Root = (props) => (
  <Provider {...store}>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <App {...props} />
    </ThemeProvider>
  </Provider>
);

export default Root;