import { Button } from '@material-ui/core';
import classnames from 'classnames';

import Logo from '../logo';
import style from './style';

import {
  BASE_ROUTE
} from '../../config';

const Header = ({
  classes,
  currentPage,
  nav,
  queueUrl,
  setupUrl,
  playSound
}) => {
  // component props and attributes for cleaner markup
  const props = {
    root: {
      className: classnames([
        style.root,
        nav === false ? style.rootNavless : '',
        ...classes,
      ]),
    },
    logo: {
      small: true,
      href: `${BASE_ROUTE}/`,
    },
    nav: {
      setup: {
        id: 'setup-tab-btn',
        className: classnames([
          style.nav__button,
          currentPage === 'setup' ? style.nav__buttonActive : '',
        ]),
        onClick: playSound,
        href: setupUrl,
        children: 'Setup',
      },
      queue: {
        id: 'q-tab-btn',
        className: classnames([
          style.nav__button,
          currentPage === 'manage' ? style.nav__buttonActive : '',
        ]),
        onClick: playSound,
        href: queueUrl,
        children: 'Queue',
      }
    }
  };

  // render
  return (
    <header {...props.root}>
      <Logo {...props.logo} />

      { nav === true
        ? (
          <nav>
            <Button {...props.nav.setup} />
            <Button {...props.nav.queue} />
          </nav>
        ) : '' }
    </header>
  );
};

Header.defaultProps = {
  nav: true,
  currentPage: '',
  setupUrl: '',
  queueUrl: '',
  classes: [],
  playSound: () => {}
};

export default Header;
