/**
 * Preact
 */
import { Component } from 'preact';
import {
  Router,
  route,
} from 'preact-router';

import {
  observer,
  inject
} from 'mobx-react';


// Code-splitting is automated for routes
import Home from '../routes/home';
import Queue from '../routes/queue';
import Register from '../routes/register';
import Admin from '../routes/admin';
import AdminRegister from '../routes/admin-register';
import AdminQueue from '../routes/admin-queue';
import About from '../routes/about';
import PageNotFound from '../routes/page-not-found';
import Print from '../routes/print';
import ServiceNotAvailable from '../routes/service-not-available';

/**
 * Project components
 */
import Footer from '../components/footer'
import Header from '../components/header';

/**
 * Other libraries and assets
 */
import Firebase from '../utils/firebase';
import style from './style';

import {
  SOUNDS_BUSINESS,
  BASE_ROUTE
} from '../config';

import Sounds from '../utils/sounds';

// GH-235
// this is custom sub-folder/base folder route handler
// ref: https://stackoverflow.com/a/59142628
class SubfolderRouter extends Router {
  render(props, state) {
    if (state.url.indexOf(BASE_ROUTE) == 0) {
      state = {
        ...state,
        url: state.url.substr(BASE_ROUTE.length),
      };
    }
    return super.render(props, state);
  }
}

@inject('globalStore')
@observer
export default class App extends Component {
  _isDevMode = process.env.NODE_ENV === 'development';
  _soundLib = null;
  _firebase = null;
  _firebaseInstance = null;

  state = {
  };

  constructor() {
    super();
  }

  async componentDidMount() {
    const {
      globalStore
    } = this.props;

    this._injectFirebaseLib();

    this._firebase = new Firebase(this._firebaseLibReady);
    this._firebase.loadFirebaseLib();
    this._soundLib = new Sounds();

    /**
     * Add event handler for 'beforeinstallprompt' event.
     * Stores event for later use and displays banner if allowed.
     * We want to toggle the visibility only when this event fires so that
     * the banner isn't shown on browsers that don't offer the install feature.
     */
    if (typeof window !== 'undefined') {
      window.addEventListener('beforeinstallprompt', (e) => {
        e.preventDefault();
        globalStore.setInstallPrompt(e);
      });
    }

    // The issue: Sounds on mobile (primarily iOS) require user intent/interaction to work.
    // As the customer queue page no longer requires the user to "get a ticket" no interaction
    // is captured to trigger sounds.

    // To get around this we should listen
    // for the first touch event and play once an empty buffer on the
    // first click event.This way audio notifications will then play.
    // ref: https://developer.mozilla.org/en-US/docs/Web/API/Web_Audio_API/Best_practices
    document.body.addEventListener('click', this._silentAudioResume, true);
  }

  _injectFirebaseLib() {
    if (typeof window !== 'undefined') {
      const check = document.getElementById('firebase-lib');

      if (!check) {
        const head = document.getElementsByTagName('head')[0];
        const firebaseAppScript = document.createElement('script');

        firebaseAppScript.id = 'firebase-lib';
        firebaseAppScript.src = 'https://www.gstatic.com/firebasejs/7.16.1/firebase-app.js';


        firebaseAppScript.addEventListener('load', () => {
          const scripts = [
            'https://www.gstatic.com/firebasejs/7.16.1/firebase-auth.js',
            'https://www.gstatic.com/firebasejs/7.16.1/firebase-database.js',
            'https://www.gstatic.com/firebasejs/7.16.1/firebase-messaging.js'
          ];

          scripts.forEach((script, i) => {
            const scriptTag = document.createElement('script');
            scriptTag.src = script;

            head.appendChild(scriptTag);
          });

        });

        head.appendChild(firebaseAppScript);
      }
    }
  }

  _firebaseLibReady = () => {
    this._firebaseInstance = this._firebase.getFirebaseInstance();

    this._firebaseInstance.auth().onAuthStateChanged(async (currentUser) => {
      const {
        globalStore
      } = this.props;
      const {
        setUserSession
      } = globalStore;

      if (!currentUser) {
        try {
          await this._signIn();
        } catch (error) {
          // TODO: handle what to do if sign in fails
          console.error(error);
          route(`${BASE_ROUTE}/`, true);
        }
        return;
      }

      setUserSession(currentUser ? {
        uid: currentUser.uid
      } : null);
    });
  }

  /** Gets fired when the route changes.
   *	@param {Object} event		"change" event from [preact-router](http://git.io/preact-router)
    *	@param {string} event.url	The newly routed URL
    */
  handleRoute = (e) => {
    this.currentUrl = e.url;

    // if it's in dev mode, there is no need to update manifest link
    if (!this._isDevMode) {
      const activePage = e.active[0];

      let manifestHref = `${BASE_ROUTE}/manifest`;

      if (activePage.props.businessId) {
        // update manifest link with business id
        // let's update manifest
        manifestHref = `${BASE_ROUTE}/manifest?businessId=${activePage.props.businessId}`;
      }

      typeof window !== 'undefined' && document.querySelector('link[rel="manifest"]').setAttribute('href', manifestHref);
    }
  };

  _setLoader(loader) {
    const { globalStore } = this.props;
    const { setLoader } = globalStore;

    setLoader(loader);
  }

  _signIn = () => {
    return this._firebaseInstance && this._firebaseInstance.auth().signInAnonymously();
  }

  _silentAudioResume = async () => {
    await this._soundLib.playEmptySound();

    // remove touch event listeners once done
    document.body.removeEventListener('click', this._silentAudioResume, true);
  }

  _playSound = (soundType) => {
    this._soundLib.playSound(soundType);
  }

  render() {
    const { globalStore } = this.props;
    const { adminState, showFooter } = globalStore;

    // component props and attributes for cleaner markup
    const props = {
      root: {
        id: 'app',
        className: style.root,
      },
      header: {
        classes: [style.header],
        nav: !!adminState,
        currentPage: adminState ? adminState.path : '',
        setupUrl: adminState ? `${BASE_ROUTE}/admin/${adminState.id}` : '',
        queueUrl: adminState ? `${BASE_ROUTE}/admin/${adminState.id}/manage` : '',
        playSound: () => {
          this._playSound(SOUNDS_BUSINESS.CLICK);
        }
      },
      content: {
        className: style.content,
      },
      footer: {
        classes: [style.footer],
      },
    }

    // render
    return (
      <div {...props.root}>
        { !adminState ? '' : <Header {...props.header} />}

        <div {...props.content}>
          <SubfolderRouter onChange={this.handleRoute}>
            <Home path='/' playSound={this._playSound} />
            <Queue path='/queue/:storeCode/' playSound={this._playSound} />
            <Queue path='/queue/:storeCode/:ticketId' playSound={this._playSound} />
            <Register path='/register/' playSound={this._playSound} />
            <Admin path='/admin/:businessId' playSound={this._playSound} />
            <AdminRegister path='/admin/:businessId/register/' playSound={this._playSound} />
            <AdminQueue path='/admin/:businessId/manage/' playSound={this._playSound} />
            <Print path='/admin/:storeCode/print/' playSound={this._playSound} />
            <About path='/about/' playSound={this._playSound} />
            <PageNotFound type="404" default playSound={this._playSound} />
            <ServiceNotAvailable path="/service-not-available" />
          </SubfolderRouter>
        </div>

        { showFooter
          ? <Footer {...props.footer} />
          : '' }
      </div>
    );
  }
}
