/**
 * Other libraries and assets
 */
import classnames from 'classnames';
import style from './style';
import {
  BASE_ROUTE
} from '../../config';

const Footer = ({classes = []}) => {
  // component props and attributes for cleaner markup
  const props = {
    root: {
      class: classnames([
        style.root,
        ...classes,
      ])
    },
    divider: {
      className: style.divider,
    },
    links: {
      root: {
        class: style.link,
        color: 'inherit',
        rel: 'noreferrer noopener',
        target: '_blank',
      },
      privacy: {
        children: 'Privacy Policy',
        href: 'https://policies.google.com/privacy',
      },
      terms: {
        children: 'Terms of Service',
        href: 'https://policies.google.com/terms',
      },
      about: {
        class: style.link,
        color: 'inherit',
        children: 'About',
        href: `${BASE_ROUTE}/about`,
      },
    }
  };

  // render
  return (
    <div {...props.root}>
      <a {...props.links.about} />
      <div {...props.divider}>•</div>
      <a {...props.links.root} {...props.links.privacy} />
      <div {...props.divider}>•</div>
      <a {...props.links.root} {...props.links.terms} />
    </div>
  );
}

export default Footer;
