import style from './style';
import classNames from 'classnames/bind';

const Logo = ({
  small = false,
  className = '',
  href = null
}) => {
  let classes = classNames.bind(style)({
    logo: true,
    logo__small: !!small,
  });

  if (href === null) {
    return (
      <div class={className}>
        <h1 class={classes}>Line Up</h1>
      </div>
    )
  } else {
    return (
      <a href={href} class={className}>
        <h1 class={classes}>Line Up</h1>
      </a>
    )
  }
}

export default Logo;
