const AudioContext = typeof window !== 'undefined' ? window.AudioContext || window.webkitAudioContext : null;

import {
  BUCKET_FILE_BASE_URL
} from '../config.js';

export default class Sounds {
  _storeDecodeAudioData = {};
  _audioContext = null;

  AUDIO_LIST = {
    alert_error: {
      wav: `${BUCKET_FILE_BASE_URL()}assets/sounds/alert_error-01.wav`,
      ogg: `${BUCKET_FILE_BASE_URL()}assets/sounds/alert_error-01.ogg`
    },
    hero_decorative: {
      wav: `${BUCKET_FILE_BASE_URL()}assets/sounds/hero_decorative-celebration-01.wav`,
      ogg: `${BUCKET_FILE_BASE_URL()}assets/sounds/hero_decorative-celebration-01.ogg`
    },
    navigation_forward: {
      wav: `${BUCKET_FILE_BASE_URL()}assets/sounds/navigation_forward-selection-minimal.wav`,
      ogg: `${BUCKET_FILE_BASE_URL()}assets/sounds/navigation_forward-selection-minimal.ogg`
    },
    state_change: {
      wav: `${BUCKET_FILE_BASE_URL()}assets/sounds/state-change_confirm-down.wav`,
      ogg: `${BUCKET_FILE_BASE_URL()}assets/sounds/state-change_confirm-down.ogg`
    },
    ui_tap: {
      wav: `${BUCKET_FILE_BASE_URL()}assets/sounds/ui_tap-variant-01.wav`,
      ogg: `${BUCKET_FILE_BASE_URL()}assets/sounds/ui_tap-variant-01.ogg`
    }
  };

  constructor() {
    if (AudioContext) {
      this._audioContext = new AudioContext({
        latencyHint: 'interactive',
        sampleRate: 44100
      });
      this.__audioUnlocked = false;
    }
  }

  playSound = async (sound) => {
    if (!this._audioContext) {
      console.error('unable to initialise audio context');
      return;
    }

    if (!this._storeDecodeAudioData[sound]) {
      //
      try {
        this._fetchAudioFile(sound);
      } catch (e) {
        console.error('fetchAudioFiles: Error: ', e);
        return;
      }
    }

    // If an AudioContext is created prior to the document receiving a user gesture,
    // it will be created in the "suspended" state, and you will need to call
    // resume() after a user gesture is received
    await this._audioContext.resume();
    const source = this._audioContext.createBufferSource();
    source.buffer = this._storeDecodeAudioData[sound];
    source.connect(this._audioContext.destination);
    source.start(this._audioContext.currentTime);
  }

  playEmptySound = async () => {
    return new Promise(async (resolve, reject) => {
      // Only run if it hasn't already been unlocked.
      if (!this._audioContext) {
        console.error('unable to initialise audio context');
        resolve();
        return;
      }

      if (this.__audioUnlocked) {
        resolve();
        return;
      }

      // If an AudioContext is created prior to the document receiving a user gesture,
      // it will be created in the "suspended" state, and you will need to call
      // resume() after a user gesture is received
      await this._audioContext.resume();
      const source = this._audioContext.createBufferSource();
      source.buffer = this._audioContext.createBuffer(1, 1, 22050)
      source.connect(this._audioContext.destination);
      if (typeof source.start === 'undefined') {
        source.noteOn(0);
      } else {
        source.start(0);
      }

      source.onended = () => {
        source.disconnect(0);
        // Prevent this check from happening again.
        this.__audioUnlocked = true;
        resolve();
      }
    });
  }

  _getAudioSupportType = () =>{
    let fileType = 'wav';
    const el = document.createElement('audio');
    // If `maybe` or `probably` change to ogg
    if (el.canPlayType('audio/ogg') !== '') {
      fileType = 'ogg';
    }
    return fileType;
  }

  _fetchAudioFile = async(file) =>{
    try {
      const fileType = this._getAudioSupportType();
      const response = await fetch(this.AUDIO_LIST[file][fileType]);

      if (!response.ok) {
        console.error(`fetchAudioFiles: status: ${response.status} file: ${file[fileType]}`);
      } else {
        let arrayBuffer = await response.arrayBuffer();
        // Safari does not support promise-based syntax for decodeAudioData
        // need to use callback syntax instead
        this._audioContext.decodeAudioData(arrayBuffer, (buffer) => {
          this._storeDecodeAudioData[file] = buffer;
        }, (e) => {
          console.error('fetchAudioFiles: Error with decoding audio data: ', e);
        });
      }
      //});
    } catch (e) {
      console.error('fetchAudioFiles: Error: ', e);
    }
  }
}
