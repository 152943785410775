import { createMuiTheme } from '@material-ui/core/styles';
import {
  COLOURS,
  FONT_FAMILIES,
} from '../config';

const htmlFontSize = 16;

// The custom theme for this app
// Use the theme explorer to see all default props and values:
// https://material-ui.com/customization/default-theme/?expand-path=$.typography
const theme = createMuiTheme({
  palette: {
    background: {
      default: '#fff',
    },
    primary: {
      main: COLOURS.GREEN,
    },
    secondary: {
      main: COLOURS.GRAY_900,
    },
    text: {
      primary: COLOURS.GRAY_900,
    },
  },
  typography: {
    htmlFontSize: htmlFontSize,
    pxToRem: ((size) => `${(size / htmlFontSize)}rem`),
    fontSize: 16,
    fontFamily: FONT_FAMILIES.GOOGLE_SANS,
    h1: {
      fontFamily: FONT_FAMILIES.GOOGLE_SANS,
      letterSpacing: 0,
      fontSize: `${62 / htmlFontSize}rem`,
      lineHeight: `${66 / htmlFontSize}rem`,
    },
    h2: {
      fontFamily: FONT_FAMILIES.GOOGLE_SANS,
      fontWeight: 500,
    },
    h3: {
      fontFamily: FONT_FAMILIES.GOOGLE_SANS,
      fontWeight: 500,
    },
    h4: {
      fontFamily: FONT_FAMILIES.GOOGLE_SANS,
      letterSpacing: 0,
      fontSize: `${34 / htmlFontSize}rem`,
      lineHeight: `${40 / htmlFontSize}rem`,
    },
    h5: {
      fontFamily: FONT_FAMILIES.GOOGLE_SANS,
      fontSize: `${24 / htmlFontSize}rem`,
      letterSpacing: 0,
      lineHeight: `${32 / htmlFontSize}rem`,
    },
    h6: {
      fontFamily: FONT_FAMILIES.GOOGLE_SANS,
      fontSize: `${20 / htmlFontSize}rem`,
      fontWeight: 500,
      letterSpacing: 0,
      lineHeight: `${32 / htmlFontSize}rem`,
    },
    subtitle1: {
      fontWeight: 500,
      lineHeight: `${24 / htmlFontSize}rem`
    },
    body1: {
      fontSize: `${16 / htmlFontSize}rem`,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: `${24 / htmlFontSize}rem`,
    },
    body2: {
      fontSize: `${14 / htmlFontSize}rem`,
      fontWeight: 400,
      letterSpacing: 0,
      lineHeight: `${20 / htmlFontSize}rem`,
    },
    overline: {
      fontSize: `${12 / htmlFontSize}rem`,
      fontWeight: 500,
      letterSpacing: '0.04em',
      lineHeight: '1rem'
    },
    button: {
      fontFamily: FONT_FAMILIES.GOOGLE_SANS,
      fontSize: '1rem',
      fontWeight: 500,
      letterSpacing: '0',
      lineHeight: `${24 / htmlFontSize}rem`,
      textTransform: 'none',
    }
  },

  overrides: {
    MuiButton: {
      root: {
        padding: `${12 / htmlFontSize}rem ${24 / htmlFontSize}rem`,
        boxShadow: 'none',
        borderRadius: 8,
        fontSize: `${18 / htmlFontSize}rem`,
        fontWeight: 700,
      },
      contained: {
        boxShadow: 'none',
      },
      text: {
        padding: `${12 / htmlFontSize}rem`,
        fontSize: `${16 / htmlFontSize}rem`,
      },
      outlined: {
        padding: `${12 / htmlFontSize}rem ${24 / htmlFontSize}rem`,
      },
      textPrimary: {
        color: COLOURS.GRAY_900,
      }
    },

    MuiOutlinedInput: {
      root: {
        borderRadius: 8,
      }
    },

    MuiFormLabel: {
      root: {
        fontSize: `${14 / htmlFontSize}rem`,
        fontFamily: FONT_FAMILIES.GOOGLE_SANS,
      },
    },

    MuiIcon: {
      root: {
        fontSize: `${24 / 16}rem`,
        width: `${24 / 16}rem`,
        height: `${24 / 16}rem`,
      },
    },

    MuiCssBaseline: {
      '@global': {
        body: {
          lineHeight: `${24 / htmlFontSize}rem`,
        },
      },
    },

    MuiExpansionPanel: {
      root: {
        '&$expanded': {
          margin: '0',
        },
      },
    },

    MuiExpansionPanelSummary: {
      content: {
        margin: '20px 0',
      },
    }
  },
});

export default theme;
